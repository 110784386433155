/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "TankRaj",
  logo_name: "tank.r()",
  nickname: "tankraj / askim",
  full_name: "Tank Raj Joshi",
  subTitle: "Project Manager, Tech Lead 🔥. Always learning.",
  resumeLink:
    "https://docs.google.com/document/d/1JXC2nPBjC2gRSvypy4nzwMGBs-Y8oyGX/edit?usp=sharing",
  mail: "mailto:tankraj4444@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/TankRaj",
  linkedin: "https://www.linkedin.com/in/borninbadigada/",
  gmail: "tankraj4444@gmail.com",
  gitlab: "https://gitlab.com/tankraj4444",
  facebook: "https://www.facebook.com/BornInBadigada/",
  twitter: "https://twitter.com/rodins_thinker",
  instagram: "https://www.instagram.com/its_tankraj/",
};

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications",
        "⚡ Building responsive website front end using ReactJS",
        "⚡ Developing mobile applications using Flutter and Android",
        "⚡ Creating application backend in Node, Express & Flask",
        "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        // {
        //   skillName: "GraphQL",
        //   fontAwesomeClassname: "simple-icons:graphql",
        //   style: {
        //     color: "#DE33A6",
        //   },
        // },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#29B0EE",
          },
        },
        // {
        //   skillName: "Visual Basic",
        //   fontAwesomeClassname: "simple-icons:dot-net",
        //   style: {
        //     color: "#029FCE",
        //   },
        // },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#CA1A22",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Nepal Open University(NOU)",
      subtitle: "Masters in E-Governance",
      logo_path: "ssec.png",
      alt_name: "SSEC",
      duration: "2022-current",
      descriptions: [
        "⚡ I'm currently pursuing my masters in E-Governance."
        // "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        // "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        // "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "http://www.pmcpatan.edu.np/",
    },
    {
      title: "Patan Multiple Campus(TU)",
      subtitle: "Bachelor of Computer Science and Information Technology",
      logo_path: "ssec.png",
      alt_name: "SSEC",
      duration: "2012-2016",
      descriptions: [
        // "⚡ I'm currently pursuing my bachelors in Information Technology.",
        "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        // "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        // "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "http://www.pmcpatan.edu.np/",
    },
    {
      title: "PCL",
      subtitle: "Intermediate in Science(I.Sc.)",
      logo_path: "ssgandhy.png",
      alt_name: "SSGC",
      duration: "2010-2012",
      descriptions: [
        "⚡ I have studied core subjects like Physics, Biology, Chemistry, Maths, etc."
        // "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        // "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        // "⚡ I have implemented several projects based on what I've leart under my Computer Engineering course. ",
      ],
      website_link: "http://www.amricampus.edu.np/",
    },
  ],
};

const certifications = {
  certifications: [
    // {
    //   title: "M0001: MongoDB Basics",
    //   subtitle: "MongoDB University",
    //   logo_path: "mongo.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/12se6bMep48WXA1PlizTBAgCnW0GXM6wq/view?usp=sharing",
    //   alt_name: "MongoDB University",
    //   // color_code: "#2AAFED",
    //   color_code: "#47A048",
    // },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Professional Summary",
  description:
    "Project manager with over 5+ years of experience of developing, architecting, leading and delivering highly reliable and scalable systems / software across a variety of areas and technologies, such as internet / enterprise applications, project management, e-commerce, financial systems, etc. Currently working as a Project Manager for E-Commerce system and E-governance based integrated system at SysQube Technology Pvt Ltd.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Jr. Android Developer",
          company: "Mantra Ideas Pvt. Ltd.",
          company_url: "http://mantraideas.com/",
          logo_path: "valora.jpg",
          duration: "Feb 2016– Aug 2016 ",
          location: "Shankhamul, Kathmandu",
          description: `Worked on Mob-rule Application and Admin Panel Backend. I have worked on Socket.io,
          Payment Gateway Integration and Microservice Architecture.
          `,
          color: "#0071C5",
        },
        {
          title: "Android Developer",
          company: "Accessible ICT Pvt. Ltd.",
          company_url: "http://accessibleict.com.au/",
          logo_path: "valora.jpg",
          duration: "May 2017– Aug 2018 ",
          location: "Kumaripati, Lalitpur",
          description: `Train, manage and provide guidance to junior software development staff.
          Work closely with the Project Manager and Team Leads on change request functions.
          Develops new and maintains existing applications.
          Increased productivity and problem-solving technics by 20%.
          We primarily used PERN and MERN Stack. 80% of my work was doing backend development and the remaining 20% was DevOps-related tasks.
          `,
         color: "#0071C5",
        },
        {
          title: "Android Developer",
          company: "Xtrastaff Pty. Ltd.",
          company_url: "http://xtrastaff.com/",
          logo_path: "nightowls.jpg",
          duration: "Sep 2018– May 2020 ",
          location: "Baluwatar, Kathmandu",
          description: `Work closely with the Client and Team on change request functions.
          We Primarily used LAMP Stack. 40% of my work was doing backend development, 30% of my work was designing database schemas, and 30% of my work was on maintain hosting and user traffics on the website.
          `,
         color: "#ee3c26",
        },
        {
          title: "Sr. Android Developer(Associate Team Lead)",
          company: "Infodevelopers Pvt. Ltd.",
          company_url: "http://infodevelopers.com.np/",
          logo_path: "nightowls.jpg",
          duration: "Sep 2020– Aug 2021 ",
          location: "Sanepa, Lalitpur",
          description: `Work on some critical bug fixes and work/co-ordinate with clients, maintain backlogs lead mobile app development team of size 14-16 devs along with backlog and resource management.
          `,
         color: "#ee3c26",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        
        {
          title: "SACS Student Volunteer(Secretary)",
          company: "Google Developer Groups",
          company_url: "http://cdcsit.edu.np/sacs/",
          logo_path: "gdg.png",
          duration: "Feb 2013 - Jan 2014",
          location: "Mahendranagar, Kanchanpur",
          description:
            "Drive and organize some IT awareness programs on different colleges for 10+2 students and organize intra-college programs.",
          color: "#D83B01",
        }
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My best experience is with Java/Kotlin for Android, Flutter/React-Native for cross paltform hybrid apps and Some JS frameworks for web-based applications.Find the projects links by clicking the button below :)",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profile.png",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I prefer Twitter to speak my mind, so follow if you think you can bear my tweets :D",
    link: "https://twitter.com/rodins_thinker",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "All apps listed on my CV please find them there",
      url: "https://docs.google.com/document/d/1JXC2nPBjC2gRSvypy4nzwMGBs-Y8oyGX/edit#",
      description:
        "TL;DWr: I am lazy to list the whole projects here so please find them on my CV on the link given",
      languages: [
        {
          name: "Java/Kotlin/Dart/Javascript",
          iconifyClass: "logos-flutter",
        }
        // ,
        // {
        //   name: "NodeJs",
        //   iconifyClass: "logos-nodejs",
        // },
      ],
    }
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
